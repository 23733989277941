<template>
    <div>
        <div class="modal_header">
            <el-row :gutter="10">
                <el-col :xs="24" :sm="24">
                    <div class="grid-content bg-purple">
                        <div class="title_h">
                            {{ $t('message.update_category_service') }}
                        </div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24">
                    <div class="grid-content bg-purple-light">
                        <el-row class="button_r">
                           <el-button :loading="loadingButton" :disabled="loadingButton ? true : false" type="success"  @click="submitTransaction(true)">{{ $t('message.save_and_close') }}</el-button>
                            <el-button @click="resetForm('formCategoryServiceUpdate')">{{ $t('message.close') }}</el-button>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-form ref="formCategoryServiceUpdate" :model="form" :rules="rules" class="aticler_m">
            <el-row :gutter="20">

                <el-col :span="10">
                    <el-form-item :label="columns.name.title" prop="name">
                        <el-input v-model="form.name" autocomplete="off"></el-input>
                    </el-form-item>
                </el-col>
                <!-- end col -->

                <el-col :span="10">
                    <el-form-item :label="columns.code.title" prop="code">
                        <el-input v-model="form.code" autocomplete="off"></el-input>
                    </el-form-item>
                </el-col>
                <!-- end col -->


            </el-row>
            <!-- end row -->
        </el-form>
    </div>
</template>

<script>
    import {mapGetters,mapActions} from 'vuex'
    import form from '@/utils/mixins/form';
    export default {
        mixins: [form],
        props: {
            reloadModel: {type: Boolean, default: true}
        },
        data(){
            return {}
        },

        created(){},

        computed: {
            ...mapGetters({
                model: 'categoryServices/model',
                rules: 'categoryServices/rules',
                columns: 'categoryServices/columns',
            })
        },

        watch: {
            reloadModel: {
                handler: function () {
                    if (this.reloadModel){
                        this.loadModel()
                    }
                },
                deep: true
            },
        },

        methods:{
            ...mapActions({
                save: 'categoryServices/update',
            }),
            loadModel(){
                this.form = JSON.parse( JSON.stringify( this.model ));
            },
            submitTransaction(close = true){
                this.$refs['formCategoryServiceUpdate'].validate((valid) => {
                    if (valid){
                    this.loadingButton = true;
                        this.save(this.form)
                            .then(res => {
                                this.parent().listChanged();
                                this.loadingButton = false;
                                this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
                                this.$refs['formCategoryServiceUpdate'].resetFields();
                                if (close)
                                    this.$emit('c-close',{drawer: 'drawerUpdate'});
                            })
                            .catch(err => {
                                this.loadingButton = false;
                                this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
                            });
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.$emit('c-close',{drawer: 'drawerUpdate'});
            },
        }
    }

</script>
